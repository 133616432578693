<style type="text/css">
  @import "~@/assets/css/table.css";
</style>
<script>
  export default {
    components: {},
    data() {
      return {

        isActive: 1,
        pathArr: [
          {
            index: 0,
            url: "/admin/workList/all",
            name: "所有工单",
          },{
            index: 1,
            url: "/admin/workList",
            name: "我的所有工单",
          },
          {
            index: 2,
            url: "/admin/workList/0",
            name: "待受理工单",
          },
          {
            index: 6,
            url: "/admin/workList/3",
            name: "处理中的工单",
          },
          {
            index: 8,
            url: "/admin/workList/1",
            name: "已关闭的工单",
          },
          {
            index: 9,
            url: "/admin/workList/self",
            name: "我创建的工单",
          }
        ]
      }
    },
    mounted() {
      this.isActive = window.location.pathname

    },
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        },
      },
    },

  }
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box flexList justify-content-between">
        <div class="flexList col-4 p-0">
          <a :href="obj.url" class="col-sm-3 p-0 mr-3" v-for="obj in pathArr" :key="obj.index">
            <div class="base-header-box " :class="{active:isActive==obj.url}">{{obj.name}}</div>
          </a>
          <!-- <a href="/admin" class="col-3 pr-0">
            <div class="base-header-box">我的未完成工单</div>
          </a>
          <a href="/admin" class="col-3 pr-0">
            <div class="base-header-box">待我分派的工单</div>
          </a>
          <a href="/admin" class="col-3 pr-0">
            <div class="base-header-box">抄送给我的工单</div>
          </a>
          <a href="/admin" class="col-3 pr-0">
            <div class="base-header-box">星标关注的工单</div>
          </a>
          <a href="/admin" class="col-3 pr-0">
            <div class="base-header-box">处理中的工单</div>
          </a>
          <a href="/admin" class="col-3 pr-0">
            <div class="base-header-box">暂停的工单</div>
          </a>
          <a href="/admin" class="col-3 pr-0">
            <div class="base-header-box">已关闭的工单</div>
          </a>
          <a href="/admin" class="col-3 pr-0">
            <div class="base-header-box">我创建的工单</div>
          </a> -->


        </div>
        <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
