<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .custom-switch {
    margin-top: unset;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import WorkorderHeader from "./workorder-header";
  import {
    workOrderListPage,
    workOrderSelfListPage,
    workOrderAllListPage
  } from "@/api/admin/workOrder/apply";
  import applyOrder from "./order/applyOrder";
  import YzSearch from "@/components/form/yzSearch.vue";
  import {
    getDicts
  } from "@/api/common/dict";
  import {
    deleteWorkOrderApply,
    applyWorkOrderApply
  } from "@/api/admin/workOrder/apply";

  /**
   * 工单列表
   */
  export default {
    components: {
      Layout,
      WorkorderHeader,
      applyOrder,
      YzSearch,
    },
    data() {
      return {
        items: [{
            text: "工单管理",
            href: "/admin"
          },
          {
            text: "所有工单",
            active: true
          }
        ],
        defaultvalue: 1,
        currentpage: 1,
        checked: true,
        pageData: {
          sid: ""
        },
        tableList: [],
        search: []
      };
    },
    methods: {
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getList();
      },
      searchClick() { //搜索时先将分页的页码归1
        this.pageData.pageNum = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      getList() {
        let func = workOrderListPage
        if (this.$route.params.id == 'self') {
          func = workOrderSelfListPage
        } else if (this.$route.params.id == 'all') {
          func = workOrderAllListPage
        } else {
          this.pageData.applyStatus = this.$route.params.id
        }
        func(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
            this.pageData.pageNum = res.pageNum;
            this.pageData.pageSize = res.pageSize;
          }
        })
      },
      deleteItem(id) {
        this.$confirm(`此操作将永久删除该【${id}】的数据, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteWorkOrderApply(id).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          })
        });
      },
      applyOrder(id) {
        this.$confirm("是否受理该工单?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          applyWorkOrderApply(id).then(res => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "受理成功!",
              });
              this.getList();
            }
          })
        });
      }
    },
    mounted() {
      this.getList()
      getDicts("gddl", "gdxl").then(res => {
        this.search = [{
            title: "大类",
            bound: ["sid"],
            type: "select",
            dict: JSON.parse(JSON.stringify(res.gddl)),
          },
          {
            title: "小类",
            bound: ["tid"],
            type: "select",
            filter: "sid",
            dict: JSON.parse(JSON.stringify(res.gdxl)),
          },
          {
            title: "状态",
            bound: ["applyStatus"],
            type: "select",
            dict: [{
                name: "已受理",
                value: "0"
              },
              {
                name: "已完结",
                value: "1"
              },
              {
                name: "正在处理",
                value: "2"
              },
              {
                name: "已回复",
                value: "3"
              },
              {
                name: "待用户反馈",
                value: "4"
              },
            ],
          },
          {
            title: "优先级",
            bound: ["applyLevel"],
            type: "select",
            dict: [{
                name: "普通",
                value: "normal"
              },
              {
                name: "重要",
                value: "important"
              },
            ],
          },
          {
            title: "工单简述",
            bound: "keyword",
            type: "input",
          },
        ]
      })
    }
  };
</script>

<template>
  <Layout>
    <WorkorderHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">

        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <yz-search :data="search" v-model="pageData" @seachList="searchClick"></yz-search>
                <b-button @click="$refs.applyOrder.show()" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>发起工单
                </b-button>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width:3%;">序号</th>
                    <th style="width: 8%;">工单类别</th>
                    <th style="width: 8%;">工单类型</th>
                    <th style="width: 8%;">工单号</th>
                    <th style="width: 5%;">申请人</th>
                    <th style="width: 8%;">当前受理人</th>
                    <th style="width: 6%;">优先级</th>
                    <th style="width: 6%;">是否结束</th>
                    <th>工单简述</th>
                    <th style="width: 6%">发起时间</th>
                    <th style="width: 6%">截至时间</th>
                    <th style="width: 6%">受理</th>
                    <th style="width: 3%">删除</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td> {{ (pageData.pageNum - 1) * pageData.pageSize +  index + 1 }}</td>
                    <td>{{ obj.sortName }}</td>
                    <td>{{ obj.typeName }}</td>
                    <td><a :href="'/admin/workOrderView?wid=' + obj.wid" target="_blank" class="font-blue">
                        {{ obj.wid }}
                      </a></td>
                    <td class="text-info">{{ obj.applyXm }}</td>
                    <td>{{ obj.dealXm ? obj.dealXm : '等待受理' }}</td>
                    <td> {{ obj.applyLevel == 'normal' ? '普通' : '重要' }}</td>
                    <td :class="obj.applyStatus!=1?'text-info':''"> {{ obj.applyStatus == 1 ? '已结束' : '未结束' }}</td>
                    <td> {{ obj.applyTitle }}</td>
                    <td> {{ formatDate(obj.createTime) }}</td>
                    <td> {{ formatDate(obj.dealEndTime) }}</td>
                    <td>
                      <a :href="'/admin/workOrderHandle?wid='+obj.wid" class="blue-font"
                        v-if="obj.dealGh == userInfo.yhzh || obj.applyGh == userInfo.yhzh" target="_blank">
                        前往处理
                      </a>
                    </td>
                    <td class="tab-icon">
                      <i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.wid)"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 发起工单 -->
    <applyOrder ref="applyOrder" @success="getList()"></applyOrder>
    <!-- 处理工单 -->
    <b-modal scrollable centered title="工单处理" size="xl" title-class="font-18" hide-footer>
      <form action="" method="">
        <div>
          <div class="workorder-box">
            <div class="mb-2"><span class="text-dark mb-1">主题：账号登陆问题</span>
              <div class="grey-time ml-1">2021-12-12 11:12:23 | 系统相关 | 系统管理部门 | 李小双 |
                510101201201011234 | 考生用户工单提交
              </div>
            </div>

            <div class="flexList">
              <div class="text-dark">描述：</div>
              <div class="line1 font-size-14">
                我的账号在登录的时候显示用户密码错误，重新注册显示用户已注册，密码不能找回，请问该怎么处理。
                <a href=""><span class="problem">【附件资料】</span></a>
                <a href=""><span class="problem">【截屏资料】</span></a>

              </div>
            </div>
          </div>

          <div class="workorder-box">
            <div class="workfile-box">
              <span><i class="iconfont icon-screenshot-two"></i>截屏 </span>
              <span><i class="iconfont icon-fasfa-paperclipCopy"></i>上传附件</span>

            </div>
            <textarea rows="3" cols="" class="form-control border-top-0 handle-textarea"
              placeholder="在此输入回复消息（'/'搜索快捷回复，‘//’搜索知识库或问题库）">
            </textarea>
            <button type="button" class="btn btn-info w-sm h35 mt-2">回复</button>
          </div>
          <div class="workorder-box">
            <div class=" pb-2 text-dark border-dash">工单分派</div>
            <div class="mt-3">
              <div class="flexList mb-3">
                <div class="d-flex w-50">
                  <div class="text-dark work-info">处理人</div>
                  <i class="iconfont icon-fasfa-user  icon-position" style="left: 20px;"></i>
                  <select name="" class="form-control form-select  w-70  pl-4">
                    <option value="">张三三/系统运维部门</option>
                    <option value="">系统相关</option>
                    <option value="">其他咨询</option>
                  </select>
                  <!-- <b-dropdown size="sm" class="ml-2 w-70" variant="outline-secondary">
                    <template v-slot:button-content>
                      <i class="iconfont icon-fasfa-user"></i>
                      
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button> 2021年一级建造师</b-dropdown-item-button>
                    <b-dropdown-item-button> 2021年二级建造师</b-dropdown-item-button>
                    <b-dropdown-item-button> 普通话考试</b-dropdown-item-button>

                  </b-dropdown> -->
                </div>
                <div class="d-flex w-50">
                  <div class="text-dark work-info">抄送人</div>
                  <i class="iconfont icon-a-fasfa-user-plus  icon-position" style="left: 33px;"></i>
                  <select name="" class="form-control form-select ml-2 w-70 pl-4">
                    <option value="">张三三/系统运维部门</option>
                    <option value="">系统相关</option>
                    <option value="">其他咨询</option>
                  </select>
                  <!-- <b-dropdown size="sm" class="ml-2 w-70" variant="outline-secondary">
                    <template v-slot:button-content>
                      <i class="iconfont icon-a-fasfa-user-plus"></i>
                      （李思思/管理部门）（王五五/综合部）
                      <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item-button> 2021年一级建造师</b-dropdown-item-button>
                    <b-dropdown-item-button> 2021年二级建造师</b-dropdown-item-button>
                    <b-dropdown-item-button> 普通话考试</b-dropdown-item-button>

                  </b-dropdown> -->
                </div>

              </div>
              <div class="d-flex mb-3">
                <div class="mb-2 text-dark work-info">派单留言</div>
                <textarea rows="3" cols="" class="form-control ml-2" style="width: 87%;">              </textarea>
              </div>
              <div class="d-flex w-50 mb-3">
                <div class="text-dark work-info">优先级</div>
                <select name="" class="form-control form-select ml-2 w-70 ">
                  <option value="">p1</option>
                  <option value="">p2</option>
                  <option value="">p3</option>
                </select>
              </div>
              <div class="d-flex mb-3">
                <div class="mb-2 text-dark work-info">时间要求</div>
                <b-form-input id="date" value="2019-08-19" type="date" class="ml-2 h30" style="width: 35%;">
                </b-form-input>
              </div>
              <button type="button" class="btn btn-info w-sm h35 mt-2">提交派单</button>
            </div>
          </div>

          <div class="workorder-box position-relative border-0">
            <div class=" mb-2 text-dark">工单完成情况</div>
            <div class="position-absolute text-center w-100" style="top: -10px;">
              <button type="button" class="btn btn-outline-light btn-sm">全部</button>
              <button type="button" class="btn btn-outline-light btn-sm">仅回复</button>
              <button type="button" class="btn btn-outline-light btn-sm">附件</button>
              <button type="button" class="btn btn-outline-light btn-sm">时间轴</button>

            </div>
            <div class="work-situation d-flex">
              <div class="situation-l"><i class="iconfont icon-fasfa-user"></i></div>
              <div class="situation-r">
                <div class="situation-r-top"><span class="mr-2">张三三</span> <span>今天 10:00</span></div>
                <div class="situation-r-bottom line1">工单已派发至系统运维部 李思思 处理</div>
              </div>
            </div>
            <div class="work-situation d-flex">
              <div class="situation-l"><i class="iconfont icon-fasfa-user"></i></div>
              <div class="situation-r">
                <div class="situation-r-top"><span class="mr-2">张三三</span> <span>今天 10:00</span></div>
                <div class="situation-r-bottom line1">工单已派发至系统运维部 李思思 处理</div>
              </div>
            </div>
            <div class="work-situation d-flex">
              <div class="situation-l"><i class="iconfont icon-fasfa-user"></i></div>
              <div class="situation-r">
                <div class="situation-r-top"><span class="mr-2">张三三</span> <span>今天 10:00</span></div>
                <div class="situation-r-bottom line1">工单已派发至系统运维部 李思思 处理</div>
              </div>
            </div>
            <div class="work-situation d-flex">
              <div class="situation-l"><i class="iconfont icon-fasfa-user"></i></div>
              <div class="situation-r">
                <div class="situation-r-top"><span class="mr-2">张三三</span> <span>今天 10:00</span></div>
                <div class="situation-r-bottom line1">工单已派发至系统运维部 李思思 处理</div>
              </div>
            </div>
            <div class="work-situation d-flex">
              <div class="situation-l"><i class="iconfont icon-fasfa-user"></i></div>
              <div class="situation-r">
                <div class="situation-r-top"><span class="mr-2">张三三</span> <span>今天 10:00</span></div>
                <div class="situation-r-bottom line1">工单已派发至系统运维部 李思思 处理</div>
              </div>
            </div>

          </div>
        </div>
      </form>
    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
